import React from 'react'
import Helmet from 'react-helmet'
import {
  Layout,
  BannerSmall,
  CallUs,
  Competences,
  BannerLinks,
  TextBox
} from '../components'

import contacts from '../components/CallUs/DefaultContacts'
import boxes from '../components/BannerLinks/DefaultBox'

import BannerImage from '../assets/header.customerportal.jpg'
import JoinTheTeam from '../assets/bannerlinks/jointheteam.jpg'
import TakeOff from '../assets/bannerlinks/takeoff.jpg'


const Kompetenzen = () => (
  <Layout>
    <Helmet
      title="Überblick über die Kompetenzen der holzweg GmbH®"
      meta={[
        {
          name: 'description',
          content:
            'Wir bieten digitale Strategien für Web-Projekte ✓  usability-orientiertes responsive Web-Design ✓ Standard-Softwarelösungen ✓  sowie komplexe innovative Highend-Technologie ✓ ' },
        {
          name: 'keywords',
          content: 'kompetenzen, holzweg, digital, Strategie, Usability, Webdesgin, Softwarelösung, Technologie' },
        {
          property: 'og:description',
          content:
            'Wir bieten digitale Strategien für Web-Projekte ✓  usability-orientiertes responsive Web-Design ✓ Standard-Softwarelösungen ✓  sowie komplexe innovative Highend-Technologie ✓ '},
        {
          property: 'og:image',
          content: 'https://www.holzweg.com/static/header.customerportal-28ad9d576b1ca96d8267d790615bd8fa.jpg'}
      ]}>
    </Helmet>
    <BannerSmall image={BannerImage} title="Unser Know-how: Eine Palette an Kompetenzen für Ihren Erfolg" />
    <TextBox
      title="Vielfältige Stärken für maßgeschneiderte Lösungen"
      text="Wir liefern Strategien für Design und Technologie, um kundenzentrierte Lösungen zu schaffen. Aus dem Potential und der Philosophie unserer Kund:innen machen wir maßgeschneiderte, international wertgeschätzte Projekte - von Visualisierungen und Websites, über Apps und Animationen bis hin zu komplexen Software- und Datenbanksystemen."
      buttons={[
        {link: '#strategie', text: 'Strategie'},
        {link: '#design', text: 'Design'},
        {link: '#technologie', text: 'Technologie'}
      ]}
    />
    <Competences />
    <TextBox
      title=""
      text={
        <span>
        <h2>Sind Sie nicht sicher, ob wir das bieten, was Sie genau brauchen? </h2>
        <p>Zögern Sie nicht, uns zu kontaktieren, und gemeinsam werden wir sorgfältig Ihre Anforderungen besprechen um die optimale Lösung für Sie zu finden. Wir legen größten Wert auf Ihre Zufriedenheit!</p>
        </span>
      }
    />
    <CallUs contacts={contacts} title="Ihre Ansprechpersonen" />
    <BannerLinks boxes={boxes} />
  </Layout>
)

export default Kompetenzen
